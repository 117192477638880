<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div>
            <b-card class="mt-3"
                    :header="$t('article.group.photos')"
            >
                <b-card-text>
                    <zw-media v-model="form.media"
                              type="article"
                              accepted-files="image/*"
                              :parent_id="form.id"
                              preview
                              rename
                    ></zw-media>
                    <image-mapping-block v-model="form.image_mapping"
                                         v-if="form.type == 'configurable'"
                                         :media="form.media"
                    />
                </b-card-text>
            </b-card>

            <div class="form-actions">
                <b-row align-h="end">
                    <b-col cols="4">
                        <b-button block @click="onSubmit" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </div>
    </validation-observer>
</template>


<script>
import {mapGetters} from 'vuex'
import ImageMappingBlock from "@/components/article/ImageMappingBlock";
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import ZwMedia from "@/components/ZwMedia";

export default {
    name: 'ImagesTab',
    mixins: [commonSave],
    components: {ImageMappingBlock, ZwMedia},
    props: {
        'articleId': [Number, String],
    },
    data() {
        return {
            form: {},
            loading: true,
        }
    },
    methods: {
        ...mapGetters('Articles', ['getArticle', 'getPriceLists']),
        ...mapGetters('Articles', ['getArticle', 'getTypes', 'getArticleNetworks']),
        ...mapGetters('Category', ['getCategoryOptions']),
        ...mapGetters('Attributes', ['getAttributeSets', 'getSetAttributes']),
        ...mapGetters('CommonData', ['getUnits', 'getVats', 'getDefaultVat', 'getWeightUnits']),
        shown() {
            const articles = this.$store.dispatch('Articles/fetchArticle', this.articleId)
            Promise.all([articles])
                .then(() => {
                    this.form = JSON.parse(JSON.stringify({...this.defaultForm, ...this.getArticle()}))
                    if (Array.isArray(this.form.image_mapping)) {
                        this.form.image_mapping = {}
                    }
                    this.$store.dispatch('Attributes/fetchSetAttributes', this.form.set_id)
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        sendSubmit() {
            this.loading = true
            this.$store.dispatch('Articles/saveArticle', this.form)
                .then((response) => {
                    this.commonAfterSave(response, this.sidebar)
                })
                .catch(errors => {
                    this.$refs['observer'].setErrors(errors)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    if (this.form.type == "configurable") {
                        this.$confirm(
                            {
                                message: this.$t('common.confirmations.remove_parent_images'),
                                button: {
                                    no: this.$t('common.button.no'),
                                    yes: this.$t('common.button.yes')
                                },
                                callback: confirm => {
                                    if (confirm) {
                                        this.sendSubmit()
                                    }
                                }
                            }
                        )
                    } else {
                        this.sendSubmit()
                    }
                } else {
                    this.showValidationError()
                }
            })
        },
    },
    mounted() {
        this.shown()
    }
}
</script>