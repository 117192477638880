<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div>
            <b-row>
                <b-col cols="6">
                    <zw-input-group v-model="form.name"
                                    name="name"
                                    inputClass="modal_open_focus"
                                    :label-prefix="labelPrefix"
                                    validate="required"
                                    size="m"
                    ></zw-input-group>
                </b-col>
                <b-col cols="6">
                    <zw-input-group v-model="form.sku"
                                    name="sku"
                                    :label-prefix="labelPrefix"
                                    :readonly="(form.id && form.type == 'configurable')"
                                    validate="required"
                                    size="m"
                    ></zw-input-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="4">
                    <b-form-group :label="$t(labelPrefix+'categories')">
                        <multiselect
                            v-model="form.category_ids"
                            :options="Object.keys(getCategoryOptions()).map(cat=>parseInt(cat))"
                            :multiple="true"
                            :custom-label="opt => getCategoryOptions()[opt]"
                            size="m"
                        >
                        </multiselect>
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <zw-select-group v-model="form.type"
                                     :options="articleTypes | optionsVV"
                                     name="type"
                                     :label-prefix="labelPrefix"
                                     validate="required"
                                     size="m"
                    ></zw-select-group>
                </b-col>
                <b-col cols="4" v-if="!$root.simpleMode">
                    <zw-select-group v-model="form.set_id"
                                     :options="getAttributeSets()"
                                     name="attribute_set"
                                     text-field="name"
                                     value-field="id"
                                     :label-prefix="labelPrefix"
                                     @change="setChanged"
                                     size="m"
                    ></zw-select-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="2">
                    <zw-input-group v-model="form.sales_price"
                                    name="sales_price"
                                    :label-prefix="labelPrefix"
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    size="m"
                    ></zw-input-group>
                </b-col>
                <b-col cols="2">
                    <zw-input-group v-model="form.sales_price_brutto"
                                    name="sales_price_brutto"
                                    :label-prefix="labelPrefix"
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    @change="calcNettoPrice"
                                    size="m"
                    ></zw-input-group>
                </b-col>
                <b-col cols="2">
                    <zw-select-group v-model="form.vat"
                                     :options="getVats() | optionsVL"
                                     name="vat"
                                     :label-prefix="labelPrefix"
                                     validate="required"
                                     size="m"
                    ></zw-select-group>
                </b-col>
                <b-col cols="2">
                    <zw-select-group v-model="form.unit"
                                     :options="getUnits() | optionsVL"
                                     name="unit"
                                     :label-prefix="labelPrefix"
                                     validate="required"
                                     size="m"
                    ></zw-select-group>
                </b-col>
                <b-col cols="2">
                    <zw-input-group v-model="form.quantity"
                                    name="quantity"
                                    :label-prefix="labelPrefix"
                                    type="number"
                                    :readonly="form.stock_enabled"
                                    size="m"
                    ></zw-input-group>
                </b-col>
            </b-row>
            <b-row v-if="!$root.simpleMode">
                <b-col cols="12">
                    <div class="accordion" role="tablist">
                        <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle="'accordion-advanced'" variant="secondary">
                                    {{ $t('article.button.advanced') }}
                                </b-button>
                            </b-card-header>
                            <b-collapse id="accordion-advanced" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <b-row>
                                        <b-col cols="3">
                                            <zw-input-group v-model="form.barcode"
                                                            name="ean"
                                                            :label-prefix="labelPrefix"
                                                            type="number"
                                                            size="m"
                                            ></zw-input-group>
                                        </b-col>
                                        <b-col cols="3">
                                            <b-form-group>
                                                <legend class="col-form-label pt-0">
                                                    {{ $t(labelPrefix + 'barcode') }}
                                                </legend>
                                                <b-input-group class="input-group-mt">
                                                    <b-form-input
                                                        v-model="form.bar_code"
                                                        type="number"
                                                        class="form-control-m"
                                                        size="m"
                                                    ></b-form-input>
                                                    <b-input-group-append>
                                                        <b-button variant="info"
                                                                  size="sm"
                                                                  @click="getBarCode(form.id)"
                                                        >
                                                            <font-awesome-icon icon="barcode"></font-awesome-icon>
                                                        </b-button>
                                                    </b-input-group-append>
                                                </b-input-group>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="2">
                                            <zw-input-group v-model="form.purchase_price"
                                                            name="purchase_price"
                                                            :label-prefix="labelPrefix"
                                                            type="number"
                                                            min="0"
                                                            step="0.01"
                                                            size="m"
                                            ></zw-input-group>
                                        </b-col>
                                        <b-col cols="2">
                                            <zw-input-group v-model="form.special_price"
                                                            name="special_price"
                                                            :label-prefix="labelPrefix"
                                                            type="number"
                                                            min="0"
                                                            step="0.01"
                                                            size="m"
                                            ></zw-input-group>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="2">
                                            <zw-input-group v-model="form.weight"
                                                            name="weight"
                                                            :label-prefix="labelPrefix"
                                                            type="number"
                                                            size="m"
                                            ></zw-input-group>
                                        </b-col>
                                        <b-col cols="2">
                                            <zw-select-group v-model="form.weight_unit"
                                                             :options="getWeightUnits() | optionsVL"
                                                             name="weight_unit"
                                                             :label-prefix="labelPrefix"
                                                             size="m"
                                            ></zw-select-group>
                                        </b-col>
                                        <b-col cols="2">
                                            <zw-input-group v-model="form.base_price"
                                                            name="base_price"
                                                            :label-prefix="labelPrefix"
                                                            :labelHtml="$t(labelPrefix+'base_price') + ' <b>( &euro; / '+form.unit+' )<b>'"
                                                            type="number"
                                                            min="0"
                                                            step="0.01"
                                                            size="m"
                                            ></zw-input-group>
                                        </b-col>
                                        <b-col cols="2">
                                            <zw-input-group v-model="form.reference_value"
                                                            name="reference_value"
                                                            :label-prefix="labelPrefix"
                                                            type="number"
                                                            min="0"
                                                            step="0.01"
                                                            size="m"
                                            ></zw-input-group>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="2">
                                            <zw-select-group v-model="form.auto_adding"
                                                             name="auto_adding"
                                                             :label-prefix="labelPrefix"
                                                             :options="{
                                                                0: $t('common.button.no'),
                                                                1: $t('common.button.yes'),
                                                             }"
                                                             size="m"
                                            ></zw-select-group>
                                        </b-col>
                                        <b-col cols="2">
                                            <zw-input-group v-model="form.customs_group"
                                                            name="customs_group"
                                                            :label-prefix="labelPrefix"
                                                            type="number"
                                                            size="m"
                                            ></zw-input-group>
                                        </b-col>
                                    </b-row>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">
                    <zw-ckeditor v-model="form.description"></zw-ckeditor>
                </b-col>
            </b-row>

            <b-card class="mt-3"
                    :header="$t('common.group.custom_fields')"
                    v-if="form.customFields && Object.keys(form.customFields).length > 0 && !$root.simpleMode">

                <b-card-text>
                    <b-row>
                        <b-col v-for="field in form.customFields" :key="field.name" sm="3">
                            <custom-field v-model="form.customValues[field.name]" :field="field"></custom-field>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card>

            <b-row v-if="form.type == 'configurable'">
                <b-col cols="12">
                    <configurable-block v-model="form.config_json"></configurable-block>
                </b-col>
            </b-row>
            <template v-else-if="form.set_id">
                <b-card class="mt-3 mb-3"
                        :header="$t('article.title.configurable')"
                >
                    <b-card-text>
                        <b-row>
                            <b-col v-for="(row,key) in form.attributes" :key="key" cols="2">
                                <zw-select-group v-model="form.attributes[key]"
                                                 :options="getSetAttributes()[key] | optionsVV"
                                                 :label="key"
                                                 name="options"
                                />
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
                <b-card class="mt-3 mb-3"
                        :header="$t('article.title.properties')"
                >
                    <b-card-text>
                        <b-row>
                            <b-col v-for="(row,key) in form.properties" :key="key" cols="2">
                                {{ key }}
                                <multiselect v-model="form.properties[key]"
                                             :options="getSetAttributes()[key] ? getSetAttributes()[key] : []"
                                             :searchable="true"
                                             :multiple="true"
                                             placeholder=""
                                             :close-on-select="false"
                                             select-label=""
                                />
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </template>

            <div class="form-actions">
                <b-row align-h="end">
                    <b-col cols="4">
                        <b-button block @click="onSubmit" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </div>
    </validation-observer>
</template>


<script>
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import ZwMedia from "@/components/ZwMedia";
import ConfigurableBlock from "@/components/article/ConfigurableBlock";

export default {
    name: 'ArticleBaseTab',
    components: {ConfigurableBlock, ZwMedia},
    mixins: [commonTable, commonSave],
    props: {
        'articleId': [Number, String],
        sidebar: {}
    },
    data() {
        return {
            loading: true,
            defaultForm: {
                unit: null,
                sales_price: 0,
                sales_price_brutto: 0,
                quantity: 1,
                reference_value: 1,
                config_json: null,
                stock: [],
                customFields: {},
            },
            form: {
                attributes: {}
            },
            start_set_id: null,
            labelPrefix: 'article.label.',
        }
    },
    methods: {
        ...mapGetters('Articles', ['getArticle', 'getPriceLists']),
        ...mapGetters('Articles', ['getArticle', 'getTypes', 'getArticleNetworks']),
        ...mapGetters('Category', ['getCategoryOptions']),
        ...mapGetters('Attributes', ['getAttributeSets', 'getSetAttributes']),
        ...mapGetters('CommonData', ['getUnits', 'getVats', 'getDefaultVat', 'getWeightUnits']),
        changeModalTitle(form) {
            this.$emit('changeTitle', form.parent_id)
        },
        shown() {
            const articles = this.$store.dispatch('Articles/fetchArticle', this.articleId)
            const articleTypes = this.$store.dispatch('Articles/fetchTypes')
            const categories = this.$store.dispatch('Category/fetchOptions')
            const attributeSets = this.$store.dispatch('Attributes/fetchAttributeSets')

            Promise.all([articles, categories, articleTypes, attributeSets])
                .then(() => {
                    this.form = JSON.parse(JSON.stringify({...this.defaultForm, ...this.getArticle()}))

                    if (this.form.vat == undefined || this.form.vat == null) {
                        if (this.getDefaultVat()) {
                            this.form.vat = this.getDefaultVat();
                        } else {
                            this.form.vat = Object.keys(this.getVats())[0];
                        }
                    }
                    if (this.form.unit == undefined || this.form.unit == null) {
                        this.form.unit = Object.keys(this.getUnits())[0];
                    }
                    if (this.form.type == undefined || this.form.type == null) {
                        this.form.type = 'simple'
                    }
                    if (this.form.reference_value == undefined || this.form.reference_value == null) {
                        this.form.reference_value = 1
                    }

                    if (this.form.attributes == undefined) {
                        this.form.attributes = {}
                    }

                    this.start_set_id = this.form.set_id

                    this.setChanged(this.form.set_id)
                    this.changeModalTitle(this.form)
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    if (this.form.id && this.form.type == 'configurable' && this.form.set_id != this.start_set_id) {
                        this.$confirm(
                            {
                                message: this.$t('common.confirmations.delete_child_products'),
                                button: {
                                    no: this.$t('common.button.no'),
                                    yes: this.$t('common.button.yes')
                                },
                                callback: confirm => {
                                    if (confirm) {
                                        this.form.delete_child_products = true
                                        this.saveProduct()
                                    }
                                }
                            }
                        )
                    } else {
                        this.saveProduct()
                    }
                } else {
                    this.showValidationError()
                }
            })
        },
        saveProduct() {
            this.loading = true
            this.$store.dispatch('Articles/saveArticle', this.form)
                .then((response) => {
                    this.commonAfterSave(response, this.sidebar)
                })
                .catch(errors => {
                    this.$refs['observer'].setErrors(errors)
                    this.showValidationError()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        getBarCode(id) {
            this.$store.dispatch('Barcode/getBarCode', {id, type: 'article'})
                .then(response => {
                    this.shown()
                    let w = window.open("");
                    const image = "data:image/jpg;base64," + response.data.content
                    w.document.write('<img src="' + image + '"/>');
                    setTimeout(() => {
                        w.print();
                    }, 500)
                })
        },
        setChanged(id) {
            this.$store.dispatch('Attributes/fetchSetAttributes', id)
        },
        calcBasePrice() {
            this.form.base_price = this.form.sales_price / this.form.reference_value
        },
        calcBruttoPrice() {
            this.form.sales_price_brutto = (((parseFloat(this.form.vat) + 100) / 100).toFixed(2) * parseFloat(this.form.sales_price)).toFixed(2);
        },
        calcNettoPrice() {
            this.form.sales_price = (parseFloat(this.form.sales_price_brutto) / ((parseFloat(this.form.vat) + 100) / 100).toFixed(2)).toFixed(2);
        },
    },
    mounted() {
        this.shown()
    },
    computed: {
        articleTypes() {
            if (this.$root.simpleMode) {
                return ['simple', 'shipping']
            } else {
                return this.getTypes()
            }
        },
    },
    watch: {
        'form.sales_price': {
            handler: function () {
                this.calcBasePrice()
                this.calcBruttoPrice()
            },
            deep: true
        },
        'form.reference_value': {
            handler: function () {
                this.calcBasePrice()
            },
            deep: true
        }
    },
}
</script>