<template>
    <zw-sidebar @shown="shown">
        <template v-slot:sidebar-title>
            <b-row style="width: 100%">
                <b-col cols="10" style="font-size: 14px">
                    <template v-if="payload.id">
                        {{ $t('common.form.title.editArticle') + ' (' + payload.id + ')' }}

                        <template v-if="parent_id">
                            <font-awesome-icon class="ml-2 mr-2" icon="caret-right"/>
                            <span class="ml-2 mr-2">|</span>
                            <a @click.prevent="$root.$children[0].openModal('article-modal', {id:parent_id})"
                               style="color: #321fdb"
                            >
                                {{ parent_id }}
                                <font-awesome-icon class="ml-1" icon="external-link-alt"/>
                            </a>
                        </template>
                    </template>
                    <template v-else>
                        {{ $t('common.form.title.createArticle') }}
                    </template>
                </b-col>
            </b-row>
        </template>
        <b-tabs v-if="!loading" lazy content-class="mt-3">
            <b-tab :title="$t('article.tab.base')"
                   :active="activeTab=='base'"
            >
                <article-base-tab :article-id="payload.id"
                                  :sidebar="sidebar"
                                  @changeTitle="changeModalTitle($event)"
                ></article-base-tab>
            </b-tab>
            <b-tab :title="$t('article.tab.images')"
                   :active="activeTab=='images'"
                   v-if="payload.id"
            >
                <images-tab :article-id="payload.id"></images-tab>
            </b-tab>
            <b-tab :title="$t('article.tab.attachments')"
                   :active="activeTab=='attachments'"
                   v-if="payload.id"
            >
                <attachments-tab :article-id="payload.id"></attachments-tab>
            </b-tab>
            <template v-if="!$root.simpleMode">
                <b-tab :title="$t('article.tab.stock')"
                       :active="activeTab=='stock'"
                       v-if="payload.id"
                >
                    <stock-tab :article-id="payload.id"></stock-tab>
                </b-tab>
                <b-tab :title="$t('article.tab.stock_logs')"
                       :active="activeTab=='stock_logs'"
                       v-if="payload.id"
                >
                    <stock-log-tab :article-id="payload.id"></stock-log-tab>
                </b-tab>
                <b-tab :title="$t('article.tab.supply')"
                       :active="activeTab=='supply'"
                       v-if="payload.id"
                >
                    <supply-tab :article-id="payload.id"></supply-tab>
                </b-tab>
                <b-tab :title="$t('article.tab.price_lists')"
                       :active="activeTab=='price_lists'"
                       v-if="payload.id"
                >
                    <price-list-tab :article-id="payload.id"></price-list-tab>
                </b-tab>
                <b-tab :title="$t('article.tab.networks')"
                       :active="activeTab=='networks'"
                       v-if="payload.id"
                >
                    <networks-tab :article-id="payload.id"></networks-tab>
                </b-tab>
                <b-tab :title="$t('article.tab.simple_articles')"
                       :active="activeTab=='simple_articles'"
                       v-if="payload.id && this.getArticle() && getArticle().type=='configurable'"
                >
                    <simple-articles-tab :article-id="payload.id"></simple-articles-tab>
                </b-tab>
                <b-tab :title="$t('article.tab.config_json')"
                       :active="activeTab=='config_json'"
                       v-if="payload.id"
                >
                    <config-json-tab :article-id="payload.id"></config-json-tab>
                </b-tab>
            </template>
        </b-tabs>
    </zw-sidebar>
</template>

<script>
import ConfigurableBlock from "../../components/article/ConfigurableBlock";
import StockBlock from "../../components/article/StockBlock";
import StockTab from "../../components/article/StockTab";
import StockLogTab from "../../components/article/StockLogTab";
import SupplyTab from "../../components/article/SupplyTab";
import NetworkTab from "../../components/article/NetworkTab";
import ZwMedia from "../../components/ZwMedia";
import PriceListTab from "@/components/article/PriceListTab";
import NetworksTab from "@/components/article/NetworksTab";
import ArticleBaseTab from "@/components/article/ArticleBaseTab";
import SimpleArticlesTab from "@/components/article/SimpleArticlesTab";
import ImagesTab from "@/components/article/ImagesTab";
import ConfigJsonTab from "@/components/article/ConfigJsonTab";
import {mapGetters} from "vuex";
import AttachmentsTab from "@/components/article/AttachmentsTab";

export default {
    components: {
        AttachmentsTab,
        SimpleArticlesTab,
        ImagesTab,
        ArticleBaseTab,
        NetworksTab,
        PriceListTab, ZwMedia, NetworkTab, SupplyTab, StockLogTab, StockTab, StockBlock, ConfigurableBlock,
        ConfigJsonTab
    },
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            activeTab: 'base',
            sidebar: null,
            parent_id: null,
        }
    },
    methods: {
        ...mapGetters('Articles', ['getArticle']),
        changeModalTitle(parent_id) {
            this.parent_id = parent_id;
        },
        shown() {
            // this.$root.simpleMode
            this.sidebar = this.$children[0].$refs['sidebar']
            if (this.payload.tab) {
                this.activeTab = this.payload.tab
            }
            this.loading = false
        }
    },
}
</script>