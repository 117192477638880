<template>
    <b-card class="mt-3" :header="$t('article.title.stock')">
        <b-card-text>
            <div>
                <b-overlay :show="loading" no-wrap></b-overlay>
                <div>
                    <b-row>
                        <b-col cols="3">{{ $t('article.label.stock.warehouse') }}</b-col>
                        <b-col cols="3">{{ $t('article.label.stock.rack') }}</b-col>
                        <b-col cols="3">{{ $t('article.label.stock.shelf') }}</b-col>
                    </b-row>
                    <b-row v-for="(item,index) in inputVal"
                           :key="index"
                    >
                        <b-col cols="3">
                            <zw-select-group v-model="inputVal[index].warehouse_id"
                                             :options="getWarehouses()"
                                             name="warehouse"
                                             textField="name"
                                             valueField="id"
                                             disable-label
                                             :label-prefix="labelPrefix"
                                             @input="warehouseSelected"
                            ></zw-select-group>
                        </b-col>
                        <b-col cols="3">
                            <zw-select-group v-model="inputVal[index].rack_num"
                                             v-if="inputVal[index].warehouse_id"
                                             :options="racks[inputVal[index].warehouse_id] || []"
                                             name="rack"
                                             textField="label"
                                             valueField="rack_num"
                                             disable-label
                                             :label-prefix="labelPrefix"
                                             @input="(val)=>{rackSelected(inputVal[index].warehouse_id,val)}"
                            ></zw-select-group>
                        </b-col>
                        <b-col cols="3">
                            <zw-select-group v-model="inputVal[index].shelf_id"
                                             v-if="inputVal[index].rack_num && shelves[inputVal[index].warehouse_id]"
                                             :options="shelves[inputVal[index].warehouse_id][inputVal[index].rack_num] || []"
                                             name="shelve"
                                             textField="label"
                                             valueField="id"
                                             disable-label
                                             :label-prefix="labelPrefix"
                            ></zw-select-group>
                        </b-col>
                        <b-col cols="2">
                            <b-button variant="danger" @click="remove(index)" :title="$t('common.title.delete')">
                                <font-awesome-icon icon="trash"/>
                            </b-button>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="1">
                            <b-button variant="primary" @click="addItem">
                                <font-awesome-icon icon="plus"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </b-card-text>
    </b-card>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'StockBlock',
    props: {
        'value': [Array],
        'articleId': [Number, String],
    },
    data() {
        return {
            loading: true,
            inputVal: [],
            labelPrefix: 'article.label.stock.',
            racks: {},
            shelves: {},
        }
    },
    methods: {
        ...mapGetters('Stock', ['getWarehouses']),
        warehouseSelected(warehouseId) {
            this.loading = true
            const racks = this.$store.dispatch('Stock/fetchRacks', {warehouseId})

            Promise.all([racks])
                .then((data) => {
                    this.$set(this.racks, warehouseId, data[0].map(rack => {
                        rack.label = rack.rack_name + ' ' + rack.rack_num
                        return rack
                    }))
                })
                .finally(() => {
                    this.loading = false
                })
        },
        rackSelected(warehouseId, rackId) {
            this.loading = true
            const shelves = this.$store.dispatch('Stock/fetchShelves', {
                warehouseId,
                rackId,
                articleId: this.articleId || 0
            })

            Promise.all([shelves])
                .then((data) => {
                    if (!this.shelves[warehouseId]) {
                        this.$set(this.shelves, warehouseId, {})
                    }
                    this.$set(this.shelves[warehouseId], rackId, data[0].map(shelve => {
                        shelve.label = shelve.shelf_name + ' ' + shelve.shelf_num
                        return shelve
                    }))
                })
                .finally(() => {
                    this.loading = false
                })
        },
        remove(index) {
            this.inputVal = this.inputVal.filter((data, i) => {
                return i != index
            })
        },
        addItem() {
            this.inputVal.push({})
        },
        start() {
            this.loading = true
            this.inputVal = this.value

            if (this.inputVal) {
                this.inputVal.forEach((item, index) => {
                    if (item.warehouse_id && item.rack_num && item.id) {
                        this.warehouseSelected(item.warehouse_id)
                        this.rackSelected(item.warehouse_id, item.rack_num)
                    }

                })
            }

            this.loading = false
        }
    },
    mounted() {
        this.start()
    },
    watch: {
        'value': {
            handler: function (val) {
                this.start()
            },
            deep: true
        },
    }
}
</script>