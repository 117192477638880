<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-button
                    @click="$root.$children[0].openModal('assign-network-modal', {
                                  store_id: network.api_store_id,
                                  source_id: network.api_source_id,
                                  article_id:articleId,
                                  },shown)"
                    size="sm"
                    variant="primary"
                    class="mr-2"
                    :title="$t('common.button.assign')"
                >
                    <font-awesome-icon icon="plus"/>
                    {{ $t('common.button.assign') }}
                </b-button>
            </b-col>
        </b-row>

        <div v-for="index in network.api_qty"
             :key="index"
        >
            <b-card class="mt-2" :header="network.product[index].name">
                <b-card-text>
                    <div v-if="network.product[index]">
                        <b-row>
                            <b-col cols="3">
                                <zw-input-group v-model="network.product[index].api_sku"
                                                name="api_sku"
                                                :label-prefix="labelPrefix"
                                                readonly
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="3">
                                <zw-input-group v-model="network.product[index].name"
                                                name="name"
                                                :label-prefix="labelPrefix"
                                                readonly
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="1">
                                <zw-input-group v-model="network.product[index].price_ek"
                                                name="purchase_price"
                                                :label-prefix="labelPrefix"
                                                readonly
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="1">
                                <zw-input-group v-model="network.product[index].price_vk"
                                                name="sales_price"
                                                :label-prefix="labelPrefix"
                                                readonly
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="2">
                                <zw-input-group v-model="network.product[index].description"
                                                name="description"
                                                :label-prefix="labelPrefix"
                                                readonly
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="2">
                                <zw-input-group v-model="network.product[index].short_description"
                                                name="short_description"
                                                :label-prefix="labelPrefix"
                                                readonly
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="2">
                                <zw-input-group v-model="network.product[index].weight"
                                                name="weight"
                                                :label-prefix="labelPrefix"
                                                readonly
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="2">
                                <zw-input-group v-model="network.product[index].attribute_set_id"
                                                name="attribute_set_id"
                                                :label-prefix="labelPrefix"
                                                readonly
                                ></zw-input-group>
                            </b-col>
                            <b-col cols="2">
                                <b-form-group>
                                    <template v-slot:label>&nbsp;</template>
                                    <b-button
                                        @click="onUnassign(network.product[index])"
                                        variant="danger"
                                        :title="$t('common.title.delete')"
                                    >
                                        <font-awesome-icon icon="trash"/>
                                        {{ $t('common.button.delete') }}
                                    </b-button>
                                    <b-button v-if="network.type == 'magento'"
                                              @click="detectSetId(network.product[index])"
                                              variant="info"
                                              :title="$t('common.title.update')"
                                    >
                                        <font-awesome-icon icon="sync"/>
                                        {{ $t('common.button.check_set_id') }}
                                    </b-button>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                </b-card-text>
            </b-card>
        </div>
    </div>
</template>


<script>
export default {
    name: 'NetworkTab',
    props: {
        network: [Object],
        shown: [Function],
        articleId: [Number],
    },
    data() {
        return {
            labelPrefix: 'article.label.',
            selectedArticleNetwork: null,
        }
    },
    methods: {
        onUnassign(product) {
            this.$store.dispatch('Multichannel/unassign', product)
                .then(() => {
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        this.$t('common.toasts.success_updated'),
                        'success'
                    )
                })
                .finally(() => {
                    this.shown()
                })
        },
        detectSetId(product) {
            this.$store.dispatch('Multichannel/checkSetId', product)
                .then(() => {
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        this.$t('common.toasts.success_updated'),
                        'success'
                    )
                })
                .finally(() => {
                    this.shown()
                })
        },
    }
}
</script>