<template>
    <div>
        <b-row v-for="(price,id) in form" :key="id">
            <b-col cols="4">
                <zw-input-group v-model="form[id]"
                                name="price"
                                :label="priceLists[id]"
                                type="number"
                ></zw-input-group>
            </b-col>
        </b-row>
        <div class="form-actions">
            <b-row align-h="end">
                <b-col cols="4">
                    <b-button block @click="save" variant="primary">
                        {{ $t('common.button.save') }}
                    </b-button>
                </b-col>
            </b-row>
        </div>
    </div>
</template>


<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'PriceListTab',
    mixins: [commonTable],
    props: {
        'articleId': [Number, String],
    },
    data() {
        return {
            loading: true,
            form: {},
            priceLists: {},
        }
    },
    methods: {
        ...mapGetters('Articles', ['getArticle', 'getPriceLists']),
        save() {
            this.$store.dispatch('Articles/savePriceLists', {
                'article_id': this.articleId,
                'prices': this.form,
            }).then(() => {
                this.$root.showMessage(
                    this.$t('common.form.title.success'),
                    this.$t('common.toasts.success_updated'),
                    'success'
                )
            })
        },
        shown() {
            const articles = this.$store.dispatch('Articles/fetchArticle', this.articleId)
            Promise.all([articles])
                .then(() => {
                    this.form = {
                        '0': this.getArticle().sales_price
                    }
                    this.priceLists = {
                        '0': 'Default'
                    }
                    this.getPriceLists().forEach(priceList => {
                        const pl = this.getArticle().priceListPrices.find(item => {
                            return item.price_list_id == priceList.id
                        }) || null
                        this.form[priceList.id] = pl ? pl.price : null
                        this.priceLists[priceList.id] = priceList.name
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        }
    },
    mounted() {
        this.shown()
    }
}
</script>