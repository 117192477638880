<template>
    <div>
        <div v-for="(image,key) in media"
             :key="key"
        >
            <div><strong>{{ image.name.replace(/^.*[\\\/]/, '') }}</strong></div>
            <b-row>
                <b-col v-for="(data,index) in getSetAttributes()"
                       :key="index"
                       cols="2"
                >
                    {{ index }}

                    <multiselect v-model="inputVal[image.id+'_'+index]"
                                 :options="data"
                                 :searchable="true"
                                 :multiple="true"
                                 placeholder=""
                                 :close-on-select="false"
                                 select-label=""
                    >
                        <template class="checkbox-label"
                                  slot="option"
                                  slot-scope="props"
                                  @click.self="()=>{}"
                        >
                            <input type="checkbox"
                                   :checked="inputVal[image.id+'_'+index] && inputVal[image.id+'_'+index].includes(props.option)"
                                   @focus.prevent/>
                            {{ props.option }}
                        </template>
                    </multiselect>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'ImageMappingBlock',
    props: {
        'value': [String, Array, Object],
        media: [String, Array],
    },
    data() {
        return {
            loading: true,
            inputVal: {},
            labelPrefix: 'article.label.image_mapping.',
            racks: {},
            shelves: {},
        }
    },
    methods: {
        ...mapGetters('Attributes', ['getSetAttributes']),
    },
    mounted() {
        this.inputVal = this.value || {}
    },
    watch: {
        'inputVal': {
            handler: function () {
                this.$emit('input', this.inputVal);
            },
            deep: true
        },
    },
}
</script>