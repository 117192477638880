<template>
    <div>
        <zw-table ref="table"
                  tableName="stockLogs"
                  columnsPrefix="stock.column.log."
                  :customSource="getThis()"
                  :sticky="false"
                  sticky-header="100%"
                  :items="myProvider"
                  :fields="fields"
                  :filter="filter"
                  :selected="selected"
                  :tbody-tr-class="rowClass"
                  :disable-filter="true"
        >
            <template #cell(description)="row">
                <div v-if="row.item.offering">
                    <a @click.prevent="$root.$children[0].openModal('kva-modal', row.item.offering.id)"
                       href="#"
                    >Order
                        #{{ row.item.offering.api_order_id ? row.item.offering.api_order_id : row.item.offering.number }}</a>
                </div>
                <div v-else>{{ row.item.description }}</div>
            </template>
        </zw-table>
    </div>
</template>


<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'StockLogTab',
    mixins: [commonTable],
    props: {
        'articleId': [Number, String],
    },
    data() {
        return {
            stocks: [],
            saveFilters: false,
        }
    },
    methods: {
        ...mapGetters('Stock', ['getStockLogsTable']),
        getThis() {
            return this
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Stock/fetchStockLogs','getStockLogsTable');
        },
        ctxBaseParams() {
            return {
                'articleId': this.articleId,
            }
        },
        rowClass(item) {
            if (item) {
                if (item.action_type == 'reserv' && item.refunds) {
                    if (item.refunds[item.position_id]) {
                        if (item.updated_at < item.refunds[item.position_id]) {
                            return 'type_transfer'
                        }
                    }
                }
                return 'type_' + item.action_type
            }
        },
    }
}
</script>

<style>
.type_order {
    background: #ffff005e !important;
}

.type_receipt {
    background: #0000ff45 !important;
}

.type_refund {
    background: #ff000054 !important;
}

.type_reserv {
    background: #0080008c !important;
}

.type_transfer {
    background: #ffa50080 !important;
}
</style>