<template>
    <div>
        <b-row>
            <b-col class="text-right">
                <b-button variant="primary"
                          @click="openAppendStock"
                          class="mr-2"
                >
                    <font-awesome-icon icon="plus"/>
                    {{ $t('stock.button.append') }}
                </b-button>
                <b-button variant="info"
                          @click="openStockMove()"
                >
                    <font-awesome-icon icon="dolly"/>
                    {{ $t('stock.button.move') }}
                </b-button>
            </b-col>
        </b-row>
        <zw-table ref="table"
                  tableName="stocks"
                  columnsPrefix="stock.column."
                  :customSource="getThis()"
                  :sticky="false"
                  sticky-header="100%"
                  :items="myProvider"
                  :fields="fields"
                  :filter="filter"
                  :selected="selected"
                  :tbody-tr-class="rowClass"
                  :disable-filter="true"
        >
            <template #cell(actions)="row">
                <b-button
                    @click="openStockPush(row.item)"
                    size="sm"
                    variant="success"
                    class="mr-2"
                    :title="$t('common.title.add')"
                >
                    <font-awesome-icon icon="plus"/>
                </b-button>
                <b-button
                    @click="openStock(row.item)"
                    size="sm"
                    variant="info"
                    class="mr-2"
                    :title="$t('common.title.edit')"
                >
                    <font-awesome-icon icon="edit"/>
                </b-button>
                <b-button
                    @click="openStockMove(row.item.id)"
                    size="sm"
                    variant="info"
                    class="mr-2"
                    :title="$t('common.title.move')"
                >
                    <font-awesome-icon icon="dolly"/>
                </b-button>
                <b-button
                    @click="openStockDelete(row.item.id)"
                    size="sm"
                    variant="danger"
                    :title="$t('common.title.delete_from_stock')"
                    class="mr-2"
                >
                    <font-awesome-icon icon="dumpster"/>
                </b-button>
                <b-button v-if="row.item.items_cnt == 0"
                          @click="deleteStock(row.item)"
                          size="sm"
                          variant="danger"
                          class="mr-2"
                          :title="$t('common.title.delete')"
                >
                    <font-awesome-icon icon="trash"/>
                </b-button>
            </template>
        </zw-table>
    </div>
</template>


<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'StockTab',
    mixins: [commonTable],
    props: {
        'articleId': [Number, String],
    },
    data() {
        return {
            stocks: [],
            saveFilters: false
            // loading: true,
        }
    },
    methods: {
        ...mapGetters('Stock', ['getStocksTable']),
        getThis() {
            return this
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Stock/fetchStocks','getStocksTable');
        },
        ctxBaseParams() {
            return {
                'articleId': this.articleId,
            }
        },
        rowClass(item) {
            if (item) {
                return item.warning_lvl >= item.items_cnt_avail ? 'table-danger' : 'table-success'
            }
        },
        openStock(item) {
            this.$root.$children[0].openModal('stock-modal', {
                    id: item.id,
                }, this.refreshTable,
                {width: '800px'}
            )
        },
        openStockPush(item) {
            this.$root.$children[0].openModal('stock-push-modal', {
                    stock_id: item.id,
                }, this.refreshTable,
                {width: '800px'}
            )
        },
        deleteStock(item) {
            this.$removeConfirm('Stock/deleteStock', item.id, this.refreshTable)
        },
        openAppendStock() {
            this.$root.$children[0].openModal('stock-modal',
                {
                    article_id: this.articleId,
                },
                this.refreshTable,
                {width: '800px'}
            )
        },
        openStockMove(from_id = null) {
            this.$root.$children[0].openModal('stock-move-modal',
                {
                    article_id: this.articleId,
                    from_id: from_id
                },
                this.refreshTable,
                {width: '800px'}
            )
        },
        openStockDelete(from_id) {
            this.$root.$children[0].openModal('stock-delete-modal',
                {
                    article_id: this.articleId,
                    from_id: from_id
                },
                this.refreshTable,
                {width: '800px'}
            )
        }
    }
}
</script>