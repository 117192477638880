<template>
    <b-card class="mt-3" :header="$t('article.title.configurable')">
        <b-card-text>
            <div>
                <b-overlay :show="loading" no-wrap></b-overlay>
                <div>
                    <b-row>
                        <b-col cols="12">
                            <strong>{{ $t('article.label.configurable.variations_attributes') }}</strong>
                        </b-col>
                        <b-col>
                            <multiselect
                                v-model="inputVal.variation_attributes"
                                :options="Object.keys(getSetAttributes())"
                                :multiple="true"
                                :custom-label="opt => Object.keys(getSetAttributes()).find(option=>{return option==opt})"
                                select-label=""
                                @input="selectSingleOption"
                            />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12">
                            <strong>{{ $t('article.label.configurable.variations') }}</strong>
                        </b-col>
                        <b-col v-for="index in inputVal.variation_attributes" :key="index" cols="2">
                            <b-form-checkbox :value="1"
                                             :unchecked-value="0"
                                             @change="(val)=>{selectAllValues(index, val)}"
                            >
                                {{ index }}
                            </b-form-checkbox>
                            <validation-provider :name="'value_'+index"
                                                 v-if="inputVal.options"
                                                 :rules="{required:Object.keys(inputVal.options).length<=0}"
                                                 v-slot="validationContext">
                                <multiselect v-model="inputVal.options[index]"
                                             :options="getSetAttributesOptions(index)"
                                             :searchable="true"
                                             :multiple="true"
                                             placeholder=""
                                             :close-on-select="false"
                                             :class="validationContext.errors.length ? 'input-error': ''"
                                             select-label=""
                                >
                                    <template class="checkbox-label"
                                              slot="option"
                                              slot-scope="props"
                                              @click.self="()=>{}"
                                    >
                                        <input type="checkbox"
                                               :checked="inputVal.options[index] && inputVal.options[index].includes(props.option)"
                                               @focus.prevent/>
                                        {{ props.option }}
                                    </template>
                                </multiselect>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row v-if="inputVal.variation_attributes">
                        <b-col cols="12">
                            <strong>{{ $t('article.label.configurable.properties') }}</strong>
                        </b-col>
                        <b-col v-for="(data,index) in getSetAttributes()"
                               :key="index"
                               cols="2"
                               v-if="!inputVal.variation_attributes.includes(index)"
                        >
                            {{ index }}
                            <multiselect v-model="inputVal.properties[index]"
                                         :options="data"
                                         :searchable="true"
                                         :multiple="true"
                                         placeholder=""
                                         :close-on-select="false"
                                         select-label=""
                            />
                        </b-col>
                    </b-row>

                    <hr>
                    <b-row>
                        <b-col cols="12">{{ $t('article.label.configurable.categories') }}</b-col>
                    </b-row>
                    <b-row>
                        <b-col v-for="index in [2,3,4,5,6]" :key="index" cols="2">
                            <zw-select-group v-model="inputVal['category_'+index]"
                                             :name="'category_'+index"
                                             disable-label
                                             :options="getConfigurableDefaultCategories() | optionsVL"
                                             @change="categoryChanged(index)"
                            ></zw-select-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12">{{ $t('article.label.configurable.configuration') }}</b-col>
                    </b-row>
                    <b-row>
                        <b-col v-for="index in [2,3,4,5,6]" :key="index" cols="2">
                            <zw-select-group v-model="inputVal['category_'+index+'_products']"
                                             :name="'category_'+index+'_products'"
                                             disable-label
                                             :options="products[index]"
                                             text-field="name"
                                             value-field="id"
                            ></zw-select-group>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col cols="12">
                            <b-form-checkbox v-model="inputVal.another_article"
                                             value="on"
                                             :unchecked-value="0"
                            >
                                {{ $t('article.label.configurable.another_product') }}
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <template v-if="inputVal.another_article">
                        <b-row>
                            <b-col v-for="index in inputVal.variation_attributes" :key="index" cols="2">

                                <b-form-checkbox :value="1"
                                                 :unchecked-value="0"
                                                 @change="(val)=>{selectAnotherAllValues(index, val)}"
                                >
                                    {{ index }}
                                </b-form-checkbox>
                                <validation-provider :name="'value_'+index"
                                                     :rules="{required:Object.keys(inputVal.another_options).length<=0}"
                                                     v-slot="validationContext">
                                    <multiselect v-model="inputVal.another_options[index]"
                                                 :options="getSetAttributes()[index]"
                                                 :searchable="true"
                                                 :multiple="true"
                                                 placeholder=""
                                                 :close-on-select="false"
                                                 :class="validationContext.errors.length ? 'input-error': ''"
                                                 select-label=""
                                    >
                                        <template class="checkbox-label"
                                                  slot="option"
                                                  slot-scope="props"
                                                  @click.self="()=>{}"
                                        >
                                            <input type="checkbox"
                                                   :checked="inputVal.another_options[index] && inputVal.another_options[index].includes(props.option)"
                                                   @focus.prevent/>
                                            {{ props.option }}
                                        </template>
                                    </multiselect>
                                </validation-provider>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">{{ $t('article.label.configurable.categories') }}</b-col>
                        </b-row>
                        <b-row>
                            <b-col v-for="index in [8,9,10,11,12]" :key="index" cols="2">
                                <zw-select-group v-model="inputVal['category_'+index]"
                                                 :name="'category_'+index"
                                                 disable-label
                                                 :options="getConfigurableDefaultCategories() | optionsVL"
                                                 @change="categoryChanged(index)"
                                ></zw-select-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12">{{ $t('article.label.configurable.configuration') }}</b-col>
                        </b-row>

                        <b-row>
                            <b-col v-for="index in [8,9,10,11,12]" :key="index" cols="2">
                                <zw-select-group v-model="inputVal['category_'+index+'_products']"
                                                 :name="'category_'+index+'_products'"
                                                 disable-label
                                                 :options="products[index]"
                                                 text-field="name"
                                                 value-field="id"
                                ></zw-select-group>
                            </b-col>
                        </b-row>
                    </template>
                </div>
            </div>
        </b-card-text>
    </b-card>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'configurable-block',
    props: {
        'value': [String, Array, Object],
    },
    data() {
        return {
            loading: true,
            products: {},
            another_products: {},
            values: {},
            inputVal: {},
        }
    },
    methods: {
        ...mapGetters('Category', ['getConfigurableCategories', 'getConfigurableDefaultCategories']),
        ...mapGetters('Attributes', ['getSetAttributes']),
        selectAllValues(index, val) {
            if (val) {
                this.$set(this.inputVal.options, index, this.getSetAttributes()[index])
            } else {
                this.$set(this.inputVal.options, index, [])

            }
        },
        getSetAttributesOptions(index) {
            return this.getSetAttributes()[index] ?? []
        },
        selectAnotherAllValues(index, val) {
            if (val) {
                this.$set(this.inputVal.another_options, index, this.getSetAttributes()[index])
            } else {
                this.$set(this.inputVal.another_options, index, [])
            }
        },
        categoryChanged(index) {
            this.loading = true

            this.$store.dispatch('Articles/fetchCategoryArticles', this.inputVal['category_' + index])
                .then((response) => {
                    this.$set(this.products, index, response.data.articles)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        selectSingleOption() {
            this.inputVal.variation_attributes.forEach(attribute => {
                if (this.getSetAttributes()[attribute] && this.getSetAttributes()[attribute].length === 1) {
                    if (typeof this.inputVal.options[attribute] == "undefined" || this.inputVal.options[attribute].length == 0) {
                        this.$set(this.inputVal.options, attribute, this.getSetAttributes()[attribute])
                    }
                }
            })

            Object.keys(this.getSetAttributes()).forEach((index) => {
                if (!this.inputVal.variation_attributes.includes(index)) {
                    if (this.getSetAttributes()[index] && this.getSetAttributes()[index].length === 1) {
                        if (typeof this.inputVal.properties[index] == "undefined" || typeof this.inputVal.properties[index].length == 0) {
                            this.$set(this.inputVal.properties, index, this.getSetAttributes()[index])
                        }
                    }
                }
            })

            // Remove old properties
            Object.keys(this.getSetAttributes()).forEach((index) => {
                if (this.inputVal.variation_attributes.includes(index)) {
                    this.$delete(this.inputVal.properties, index)
                }
            })
        }
    },
    mounted() {
        const categories = this.$store.dispatch('Category/fetchConfigurable')

        if (!this.value || Array.isArray(this.value)) {
            let properties = {}

            this.inputVal = {
                'category_1': null,
                'category_2': null,
                'category_3': null,
                'category_4': null,
                'category_5': null,
                'category_6': null,
                'category_7': null,
                'category_8': null,
                'category_9': null,
                'category_10': null,
                'category_11': null,
                'category_12': null,
                'options': {},
                'properties': properties,
                'variation_attributes': [],
                'another_options': {},
            }

        } else {
            this.inputVal = this.value
        }

        if (Array.isArray(this.inputVal.properties)) {
            this.inputVal.properties = {}
        }

        // Fix options keys after legacy erika
        let options = {}
        Object.keys(this.inputVal.options || {}).forEach((key) => {
            options[key] = Object.values(this.inputVal.options[key])
        })
        this.inputVal.options = options

        let anotherOptions = {}
        if (this.inputVal.another_options) {
            Object.keys(this.inputVal.another_options).forEach((key) => {
                anotherOptions[key] = Object.values(this.inputVal.another_options[key])
            })
        }
        this.inputVal.another_options = anotherOptions

        Promise.all([categories])
            .then(() => {
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].forEach(index => {
                    if (this.inputVal['category_' + index]) {
                        this.categoryChanged(index)
                    }
                })
            })
            .finally(() => {
                this.loading = false
            })

    },
    watch: {
        'inputVal': {
            handler: function () {
                this.$emit('input', this.inputVal);
            },
            deep: true
        }
    },
}
</script>

<style>
.input-error .multiselect__tags {
    border: 1px solid #e55353 !important;
}

.multiselect__tag {
    background: #39f !important;
}
</style>