<template>
    <div>
        <b-tabs v-if="!loading" lazy content-class="mt-3">
            <b-tab v-for="network in networks"
                   :key="network.title"
                   :title="network.title"
            >
                <network-tab :network="network"
                             :shown="updateNetworks"
                             :article-id="articleId"
                ></network-tab>
            </b-tab>
        </b-tabs>
    </div>
</template>


<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import NetworkTab from "@/components/article/NetworkTab";

export default {
    name: 'NetworksTab',
    components: {NetworkTab},
    mixins: [commonTable],
    props: {
        'articleId': [Number, String],
    },
    data() {
        return {
            networks: {},
            loading: true,
        }
    },
    methods: {
        ...mapGetters('Articles', ['getArticleNetworks']),
        updateNetworks() {
            this.loading = true
            const articlesNetworks = this.$store.dispatch('Articles/fetchArticleNetworks', this.articleId)

            Promise.all([articlesNetworks])
                .then(() => {
                    this.networks = JSON.parse(JSON.stringify(this.getArticleNetworks()));
                })
                .finally(() => {
                    this.loading = false
                })
        },
    },
    mounted() {
        const articlesNetworks = this.$store.dispatch('Articles/fetchArticleNetworks', this.articleId)

        Promise.all([articlesNetworks])
            .then(() => {
                this.networks = JSON.parse(JSON.stringify(this.getArticleNetworks()))
            })
            .finally(() => {
                this.loading = false
            })
    }
}
</script>