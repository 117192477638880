<template>
    <div>
        <b-row>
            <b-col class="text-right">
                <b-button variant="primary"
                          @click="placeOrder"
                >
                    <font-awesome-icon icon="plus"/>
                    {{ $t('stock.button.place_order') }}
                </b-button>
            </b-col>
        </b-row>
        <zw-table ref="table"
                  tableName="supply"
                  columnsPrefix="stock.column.supply."
                  :customSource="getThis()"
                  :sticky="false"
                  sticky-header="100%"
                  :items="myProvider"
                  :fields="fields"
                  :filter="filter"
                  :selected="selected"
                  :disable-filter="true"
        >
            <template #cell(supply_id)="{item}">
                Order #{{ item.id }}
            </template>
            <template #cell(customer)="{item}">
                <div v-if="item.customer">
                    <span>{{ item.customer.agenturname }}</span>
                    <span
                        v-if="item.contactPerson">{{ item.contactPerson.vorname }} {{ item.contactPerson.name }}</span>
                </div>
                <div v-else>
                    Store order {{ item.warehouse.name }}
                </div>
            </template>
            <template #cell(actions)="row">
                <b-button v-if="row.item.customer"
                          size="sm"
                          variant="warning"
                          class="mr-2"
                >
                    <font-awesome-icon icon="file-alt"/>
                    TODO: {{ $t('stock.button.generate') }}
                </b-button>
                <b-button v-if="row.item.editable"
                          @click="$root.$children[0].openModal('supply-received-modal', {id: row.item.id,articleId},refreshTable,{width: '800px'})"
                          size="sm"
                          variant="success"
                          class="mr-2"
                >
                    <font-awesome-icon icon="box"/>
                    {{ $t('stock.button.received') }}
                </b-button>
                <b-button v-if="row.item.editable"
                          @click="$root.$children[0].openModal('supply-cancel-modal', {id: row.item.id},refreshTable,{width: '800px'})"
                          size="sm"
                          variant="info"
                          class="mr-2"
                >
                    <font-awesome-icon icon="undo"/>
                    {{ $t('stock.button.cancel') }}
                </b-button>
                <b-button v-if="row.item.editable"
                          @click="editOrder(row.item.id)"
                          size="sm"
                          variant="primary"
                          class="mr-2"
                          :title="$t('common.title.edit')"
                >
                    <font-awesome-icon icon="edit"/>
                </b-button>
            </template>
        </zw-table>
    </div>
</template>


<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'SupplyTab',
    mixins: [commonTable],
    props: {
        'articleId': [Number, String],
    },
    data() {
        return {
            stocks: [],
            saveFilters:false,
            // loading: true,
        }
    },
    methods: {
        ...mapGetters('Supply', ['getSuppliesTable']),
        getThis() {
            return this
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Supply/fetchSupplies','getSuppliesTable');
        },
        ctxBaseParams() {
            return {
                'articleId': this.articleId,
            }
        },
        placeOrder() {
            this.$root.$children[0].openModal('supply-modal', {
                id: null,
                articleId: this.articleId
            }, this.refreshTable)
        },
        editOrder(id) {
            this.$root.$children[0].openModal('supply-modal', {id: id, articleId: this.articleId})
        },
    }
}
</script>